var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('Client Settings')))])],1)]],2),_c('div',{staticClass:"mt-4"},[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"href":"#ct"}},[_vm._v(_vm._s(_vm.$t('Types of Clients')))]),_c('v-tab',{attrs:{"href":"#cf"}},[_vm._v(_vm._s(_vm.$t('Custom fields')))])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"value":"ct"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"sort-by":"name","must-sort":"","calculate-widths":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-text-field',{staticClass:"shrink mr-5",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('Search'),"single-line":"","hide-details":"","clearable":""},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}}),_c('v-btn',{attrs:{"to":{ name: 'ClientTypesCreate' },"fab":"","dark":"","color":"primary"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"to":{name: 'ClientTypesEdit', query: { id: item.id }},"plain":"","text":"","icon":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover? 'green': 'grey'}},[_vm._v(" mdi-pencil ")])]}}],null,true)})],1),_c('v-btn',{attrs:{"to":{name: 'ClientTypesDelete', query: { id: item.id,name:item.name }},"plain":"","text":"","icon":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover? 'primary': 'grey'}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('No items found'))+" ")]},proxy:true}])})],1),_c('v-tab-item',{attrs:{"value":"cf"}},[_c('v-data-table',{attrs:{"headers":_vm.CFheaders,"items":_vm.CFitems,"search":_vm.CFsearch,"options":_vm.CFoptions,"server-items-length":_vm.CFtotalItems,"loading":_vm.CFloading,"sort-by":"name","must-sort":"","calculate-widths":""},on:{"update:options":function($event){_vm.CFoptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-text-field',{staticClass:"shrink mr-5",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('Search'),"single-line":"","hide-details":"","clearable":""},model:{value:(_vm.CFoptions.search),callback:function ($$v) {_vm.$set(_vm.CFoptions, "search", $$v)},expression:"CFoptions.search"}}),_c('v-btn',{attrs:{"to":{ name: 'ClientCustomFieldCreate' },"fab":"","dark":"","color":"primary"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)],1)]},proxy:true},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getType(item.type))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"to":{name: 'ClientCustomFieldEdit', query: { id: item.id }},"plain":"","text":"","icon":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover? 'green': 'grey'}},[_vm._v(" mdi-pencil ")])]}}],null,true)})],1),_c('v-btn',{attrs:{"to":{name: 'ClientCustomFieldDelete', query: { id: item.id,name:item.name }},"plain":"","text":"","icon":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover? 'primary': 'grey'}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('No items found'))+" ")]},proxy:true}])})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }