<template>
  <div>
    <div>
        <template>
            <v-toolbar
                flat
            >
            <v-toolbar-title>{{ $t('Client Settings') }}</v-toolbar-title>
            </v-toolbar>
        </template>    
    </div>
    <div class="mt-4">
      <v-tabs v-model="tab">
        <v-tab href="#ct">{{$t('Types of Clients')}}</v-tab>
        <v-tab href="#cf">{{$t('Custom fields')}}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item value="ct">
          <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :options.sync="options"
            :server-items-length="totalItems"
            :loading="loading"
            sort-by="name"
            must-sort
            class=""
            calculate-widths
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="options.search"
                  append-icon="mdi-magnify"
                  :label="$t('Search')"
                  single-line
                  hide-details
                  clearable
                  class="shrink mr-5"
                ></v-text-field>
                <v-btn 
                    :to="{ name: 'ClientTypesCreate' }" 
                    fab
                    dark
                    color="primary"
                >
                    <v-icon
                    dark
                    >
                    mdi-plus
                    </v-icon>
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{item}" >
                <v-btn :to="{name: 'ClientTypesEdit', query: { id: item.id }}" plain text icon class="mr-2">
                    <v-hover v-slot="{ hover }">
                    <v-icon :color="hover? 'green': 'grey'"           
                    >
                    mdi-pencil
                    </v-icon>
                  </v-hover>
                </v-btn>
                
                <v-btn :to="{name: 'ClientTypesDelete', query: { id: item.id,name:item.name }}" plain text icon class="">
                    <v-hover v-slot="{ hover }">
                      <v-icon  :color="hover? 'primary': 'grey'"           
                      >
                      mdi-delete
                      </v-icon>
                    </v-hover>
                </v-btn>
            </template>
            <template v-slot:no-data>
              {{ $t('No items found') }}
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="cf">
          <v-data-table
            :headers="CFheaders"
            :items="CFitems"
            :search="CFsearch"
            :options.sync="CFoptions"
            :server-items-length="CFtotalItems"
            :loading="CFloading"
            sort-by="name"
            must-sort
            class=""
            calculate-widths
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="CFoptions.search"
                  append-icon="mdi-magnify"
                  :label="$t('Search')"
                  single-line
                  hide-details
                  clearable
                  class="shrink mr-5"
                ></v-text-field>
                <v-btn 
                    :to="{ name: 'ClientCustomFieldCreate' }" 
                    fab
                    dark
                    color="primary"
                >
                    <v-icon
                    dark
                    >
                    mdi-plus
                    </v-icon>
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:item.type="{ item }">
      
                {{ getType(item.type) }}
              
            </template>
            <template v-slot:item.actions="{item}" >
                <v-btn :to="{name: 'ClientCustomFieldEdit', query: { id: item.id }}" plain text icon class="mr-2">
                    <v-hover v-slot="{ hover }">
                    <v-icon :color="hover? 'green': 'grey'"           
                    >
                    mdi-pencil
                    </v-icon>
                  </v-hover>
                </v-btn>
                
                <v-btn :to="{name: 'ClientCustomFieldDelete', query: { id: item.id,name:item.name }}" plain text icon class="">
                    <v-hover v-slot="{ hover }">
                      <v-icon  :color="hover? 'primary': 'grey'"           
                      >
                      mdi-delete
                      </v-icon>
                    </v-hover>
                </v-btn>
            </template>
            <template v-slot:no-data>
              {{ $t('No items found') }}
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
    export default {
        data(){
            return{
                tab: null,
                search: '',
                totalItems: 0,
                options: {},
                loading: true,
                headers: [
                    { text: this.$t('Name'), align: 'start', value: 'name'},
                    { text: this.$t('Description'), value: 'description', sortable: false },
                    { text: this.$t('Actions'), align: 'right', value: 'actions', sortable: false },
                ],
                items: [],

                CFsearch: '',
                CFtotalItems: 0,
                CFoptions: {},
                CFloading: true,
                CFheaders: [
                    { text: this.$t('Name'), align: 'start', value: 'name'},
                    { text: this.$t('Type'), value: 'type' },
                    { text: this.$t('Actions'), align: 'right', value: 'actions', sortable: false },
                ],
                CFitems: [],
                types:[],
            }
        },

        watch: {
            options: {
                handler () {
                  this.fetchItems()
                },
                deep: true,
            },
            CFoptions: {
                handler () {
                  this.CFfetchItems()
                },
                deep: true,
            },
            tab:{
              handler(){
                this.$router.replace({ query: { tab: this.tab  } })
              }
            }
        },
        mounted ()
        {
            this.fetchTypes();
            this.fetchItems();
            this.CFfetchItems();
        },
        created: function()
        {
            this.fetchItems();
            this.tab = this.$route.query.tab
        },
        
        methods: {
            fetchTypes()
            {
                let uri = this.$urlPrefix + '/CustomFieldType/GetAll';
                this.axios.get(uri).then((response) => {
                    this.types = response.data;
                });
            },
            getType(typeid){
              let filterType = this.types.filter(function(type){
                return type.value == typeid
              })
              return this.$t(filterType[0].name)
            },
            fetchItems()
            {
              let uri = this.$urlPrefix + '/ClientType/CountAll';
              let uri2 = this.$urlPrefix + '/ClientType/GetList?start='+ (this.options.page -1) * this.options.itemsPerPage+'&count='+this.options.itemsPerPage;
              if(this.options.search != undefined && this.options.search != ""){
                uri2 += '&search='+this.options.search;
              }
              if(this.options.sortBy){
                uri2 += '&sort='+this.options.sortBy[0];
              }
               if(this.options.sortDesc){
                uri2 += '&sortDescending='+this.options.sortDesc[0];
              }
              this.axios.get(uri).then((response) => {
                  this.totalItems = response.data;
                this.axios.get(uri2).then((response) => {
                    this.items = response.data;
                    this.loading = false;
                });
              });
            },
            CFfetchItems()
            {
              let uri = this.$urlPrefix + '/RelationCustomField/CountAll';
              let uri2 = this.$urlPrefix + '/RelationCustomField/GetList?start='+ (this.CFoptions.page -1) * this.CFoptions.itemsPerPage+'&count='+this.CFoptions.itemsPerPage;
              if(this.CFoptions.search != undefined && this.CFoptions.search != ""){
                uri2 += '&search='+this.CFoptions.search;
              }
              if(this.CFoptions.sortBy){
                uri2 += '&sort='+this.CFoptions.sortBy[0];
              }
               if(this.CFoptions.sortDesc){
                uri2 += '&sortDescending='+this.CFoptions.sortDesc[0];
              }
              this.axios.get(uri).then((response) => {
                  this.CFtotalItems = response.data;
                this.axios.get(uri2).then((response) => {
                    this.CFitems = response.data;
                    this.CFloading = false;
                });
              });
            },
        }
    }
</script>
<style lang="scss">
a.v-tab {
    padding-left: 0px !important;
}
</style>